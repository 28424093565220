import { Flex, Image, Heading } from "@chakra-ui/react";
import logo from ".././images/logo.png";

import { Link } from "react-router-dom";

function Header() {
  return (
    <Flex
      h={{ base: "80px", md: "80px", lg: "100px" }}
      bg="#f69320"
      alignItems="center"
      justifyContent="center"
      flexDirection="row"
      
    >
      <Link to="/">
        <Image
          h={{ base: "70px", md: "70px", lg: "90px" }}
          src={logo}
          mr={5}
        />
      </Link>
      <Heading color={"white"}>Reembolse Aqui!</Heading>

    </Flex>
  );
}

export default Header;
