import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  Heading,
  Text,
  Textarea,
  Select,
} from "@chakra-ui/react";

const FormReembolso = () => {
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [dataCompra, setDataCompra] = useState("");
  const [motivo, setMotivo] = useState("");
  const [metodo, setMetodo] = useState("");
  const [produto, setProduto] = useState("");
  const toast = useToast();
  const navigate = useNavigate(); // Certifique-se de que está dentro do componente

  const { pedidoId } = useParams();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email || !telefone || !dataCompra || !motivo || !metodo || !produto) {
      toast({
        title: "Erro.",
        description: "Todos os campos devem ser preenchidos.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = {
      email: email,
      telefone: telefone,
      dataCompra: dataCompra,
      motivo: motivo,
      metodo: metodo,
      produto: produto,
      pedidoId: pedidoId,
    };
    console.log(formData);
    // Você pode adicionar a lógica para enviar esses dados para o backend aqui

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/reembolso`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
          },
          body: JSON.stringify(formData),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        toast({
          title: "Requisição enviada.",
          description:
            "Seu pedido de reembolso foi enviado com sucesso. Entraremos em contato em até 72h.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => navigate("/"), 5000); // Redireciona para a home após 5 segundos
      } else if (
        responseData.message ===
        "Reembolso já foi solicitado antes para esse pedido."
      ) {
        toast({
          title: "Atenção.",
          description: "Reembolso já foi solicitado anteriormente.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      } else if (responseData.message === "Pedido não encontrado.") {
        toast({
          title: "Atenção.",
          description: "Não encontramos nenhum pedido correspondente. Por favor, abra o site através do link enviado por e-mail ou WhatsApp.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error("Erro ao enviar a requisição");
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Erro.",
        description: "Houve um problema ao enviar seu pedido de reembolso. ",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const formatTelefone = (value) => {
    value = value.replace(/\D/g, ""); // Remove tudo que não é dígito
    value = value.replace(/^(\d{2})(\d)/g, "($1) $2"); // Adiciona parênteses ao redor dos dois primeiros dígitos
    value = value.replace(/(\d)(\d{4})$/, "$1-$2"); // Adiciona um hífen entre o quarto e quinto dígito
    return value;
  };

  const handleTelefoneChange = (event) => {
    setTelefone(formatTelefone(event.target.value));
  };

  return (
    <Box
      mx="auto"
      mt="50px"
      mb="50px"
      w={{ base: "90%", md: "60%", lg: "50%" }}
      textAlign="center"
    >
      <Heading as="h1" size="xl" mb={4}>
        Formulário de Reembolso
      </Heading>
      <Text fontSize="lg" mb={6}>
        Para processarmos sua solicitação de estorno junto com a empresa
        responsável pela venda, por favor, preencha as informações abaixo:
      </Text>

      <Box
        mx="auto"
        mt="50px"
        mb="50px"
        w={{ base: "90%", md: "60%", lg: "50%" }}
        as="form"
        onSubmit={handleSubmit}
        textAlign="center"
      >
        <FormControl id="email" mb={4}>
          <FormLabel>Email utilizado na compra</FormLabel>
          <Input
            type="email"
            placeholder="Insira seu email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            size="lg"
            focusBorderColor="#f69320"
          />
        </FormControl>

        <FormControl id="telefone" mb={4}>
          <FormLabel>Telefone</FormLabel>
          <Input
            type="tel"
            placeholder="Insira seu telefone"
            value={telefone}
            onChange={handleTelefoneChange}
            size="lg"
            focusBorderColor="#f69320"
          />
        </FormControl>

        <FormControl id="dataCompra" mb={4}>
          <FormLabel>Data de Compra</FormLabel>
          <Input
            type="date"
            placeholder="Insira a data da compra"
            value={dataCompra}
            onChange={(event) => setDataCompra(event.target.value)}
            size="lg"
            focusBorderColor="#f69320"
          />
        </FormControl>

        <FormControl id="motivo" mb={4}>
          <FormLabel>Motivo do Reembolso</FormLabel>
          <Textarea
            placeholder="Descreva o motivo do reembolso"
            value={motivo}
            onChange={(event) => setMotivo(event.target.value)}
            size="lg"
            focusBorderColor="#f69320"
          />
        </FormControl>

        <FormControl id="metodo" mb={4}>
          <FormLabel>Método de Pagamento</FormLabel>
          <Select
            placeholder="Selecione o método de pagamento utilizado"
            value={metodo}
            onChange={(event) => setMetodo(event.target.value)}
            size="lg"
            focusBorderColor="#f69320"
          >
            <option value="pix">Pix</option>
            <option value="cartao">Cartão de Crédito</option>
            <option value="boleto">Outro</option>
          </Select>
        </FormControl>

        <FormControl id="produto" mb={4}>
          <FormLabel>Qual foi o produto comprado:</FormLabel>
          <Textarea
            placeholder="Informe o nome do produto que deseja solicitar o reembolso"
            value={produto}
            onChange={(event) => setProduto(event.target.value)}
            size="lg"
            focusBorderColor="#f69320"
          />
        </FormControl>

        <Button colorScheme="orange" type="submit">
          Enviar
        </Button>
      </Box>
    </Box>
  );
};

export default FormReembolso;
