import { Flex, Text } from "@chakra-ui/react";

function Footer() {
  return (
    <Flex
      position="fixed"
      bottom="0"
      width="100%"
      bg="gray.200"
      alignItems="center"
      justifyContent="center"
      p="2"
    >
      <Text fontSize={{ base: "sm", md: "md", lg: "md" }} color="gray.500">
        2024 © Reembolse Aqui. Todos os direitos reversados.
      </Text>
    </Flex>
  );
}

export default Footer;
