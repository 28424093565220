import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import FormReembolso from "./components/FormReembolso.js";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<FormReembolso />} />
          <Route path="*" element={<FormReembolso />} />
          <Route path="/reembolso/:pedidoId" element={<FormReembolso />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
